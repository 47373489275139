@tailwind base;
@tailwind components;
@tailwind utilities;

/* Gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Applying the gradient animation */
body {
  margin: 0;
  margin-top: 60px;
  height: 100vh; /* Ensure it covers the full viewport height */
  background: linear-gradient(270deg, #000000, #0e0011, #030303); /* Tailwind's indigo-500, purple-500, pink-500 */
  background-size: 400% 400%; /* Allow for the gradient to animate */
  animation: gradientAnimation 4s ease infinite; /* Set animation duration and ease */

}


