/* Default bold white text */
.default-text {
    color: white;
    font-weight: bold;
}

/* Bold white text with neon glow for active section */
.neon-text {
    font-weight: bold;
    color: black; /* Text remains bold white */
    text-shadow: 
        0 0 5px #ade3eb,   /* Soft inner glow */
        0 0 5px #ade3eb,  /* Medium glow */
        0 0 5px #ade3eb,  /* Stronger glow */
        0 0 5px #ade3eb,  /* Strong outer glow */
        0 0 10px rgba(0, 255, 255, 0.8), /* Neon cyan glow */
        0 0 10px rgba(0, 255, 255, 0.6); /* Faint extended glow */

        transform: translate3d(1, 2, 3); /* Trigger GPU acceleration */
  will-change: transform, opacity; /* Notify browser that these will change */
  transition: transform 1s ease, opacity 1s ease; /* Smooth transition */
}

/* Style for gradient text */
.gradient-text {
  background: linear-gradient(90deg, #007BFF, #A020F0, #FF69B4 , white ); /* Blue, Purple, Pink gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
/*end header */

/*start contactUs */
#business-hours-tooltip {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.flex:hover #business-hours-tooltip {
  visibility: visible;
  opacity: 1;
}
/*end contactUs */

/* start service*/
.fixed-size-image {
  width: 100px;  /* Define fixed width */
  height: 100px; /* Define fixed height */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.marquee-container {
  height: 100px; /* Ensure the container matches the image height */
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee {
  display: flex;
  animation: marquee 20s linear infinite; /* Duration of the marquee effect */
  animation-play-state: running;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


/*end service */